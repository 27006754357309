import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Would I still choose to do the same, if I could go back? I wonder as I look out the window at the barren landscape of the hospital yard. If I would have known how all of this would turn out, would I still want to do this. Is the outcome worth the price? Well to know that, we would have to start at the very beginning.`}</p>
    <p>{`It all started in the dining room of my family's house, at dinner, on one stormy November night three years ago. We were all chatting about our daily news, and when my turn came I expressed my desire to run in the mornings more frequently, and my dad, the ingenious man he is, proposed for me to try and run professionally. This thought, though very relevant, didn’t occur to me before, and after mere seconds of contemplating I agreed. In the next few weeks I joined my local track team, and started attending training almost daily. I admit that it was hard at times, my lungs felt like they were going to explode and my whole body screamed at me to stop, threatening to collapse. But through all that I still carried on. Perhaps it was because I wanted to prove that I could be good at something, or because while running I was free and fully in control of that freedom.`}</p>
    <p>{`Fast forward a few months to my first race. I remember the overbearing urge to throw up, as I nervously warmed up. Remember the dizziness as I was kneeling at the blocks. And accelerating freedom I felt once I was soaring over the track. That was the day I discovered the pleasure of winning, and the sensation that followed. Soon one hour training turned to 4 hours, and the empty shelf in my drawer filled up with medals. I didn’t mind the late hours, and sleep deprived days. Didn’t care that I didn't have time to hang out with friends. And didn’t even flinch, when my muscles were sore. I had a goal, to be the best of the best. The best of the best at something I loved.`}</p>
    <p>{`In the few months I had been in competitive sport it became evident that I had quite a talent. I won competition after competition and after eight months of grueling practice and intense competing schedule I qualified for nationals. That day was perhaps the happiest one in my life. We went to a fancy restaurant and toasted on my success. My parents, I and the few friends I had stayed in contact with. If I knew what would follow I wouldn’t be celebrating.`}</p>
    <p>{`On the day of the Nationals, I was in full parade. Well rested and ready for the challenge ahead I arrived at the track. It was giant and grand. Much different from my home one. But I was not intimidated. I wanted this. I had what was needed to have this. I said hello to my trainer, who greeted me with a pat on the back. Tying my shoelaces and heading to warm up, I remember my nerves were especially crazy that day. My intestines were twisting inside me and It was getting hard to breath. Till this day, I still don’t know what went wrong. After I pushed off the starting blocks, I sprinted the fastest I ever did in my entire life. With the wind in my heart, and my ears numb from the starting whistle, and made my way around the track. I was first, with the group a good five meters behind me. I still continued sprinting. It felt like I was flying. I squinted my eyes. The finish line was near. As I crossed the finish line, a wave of relief passed through me. I won. I wish I was more present back then. Wish I would have noticed the water that was spilled on the track. Wish I wouldn’t have fallen. Or would at least block the impact with my hands. Instead, oblivious to the hazard, I slipped and stumbled downward. My lightning speed worsened the impact.`}</p>
    <p>{`The next thing I remembered is pain. Awful, numbing, piercing pain. In the hospital, which I was taken to by an ambulance they said I had shattered my knee-cap. Four weeks in a cast, and two months of physical therapy later, here I am. Unable to ever run again. Forbidden by my own body to feel the magical sensation. This is the price I paid for the title of National Champion. They say when one door closes another opens, in this case I think it was the ceiling which collapsed, destroying all opportunities I had. My body, which once was my means to fly, now makes each step unimaginatively painful, making it impossible to run. My dream cost me my passion. The nationals was the last day I ever ran.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      